
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
// .ButtonStandardArrow {}

.ButtonStandardArrowIcon {
  display: inline-block;
  transform: translateX(6px);
  margin-left: -6px;
}
