
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.Panel {
  border-radius: $radius-small;
  border: 1px solid $color-coolgrey-l80;
  // overflow: hidden;
  background-color: $color-white;

  &:focus-within {
    border-color: $color-blue;
    // box-shadow: 0 0 0 3px rgba($color-blue, 0.3);
  }
}

.PanelHeader {
  display: flex;
  align-items: center;
  width: 100%;
  padding: (spacing(2) - 1) spacing(2);
  line-height: spacing(3);
  text-align: left;
  transition: all $duration-standard $easing-standard;
  font-weight: 600;
  color: $color-blue;
  @include font-smoothing();

  &:focus {
    outline: 0;
  }
}

.PanelHeaderText {
  flex-grow: 1;
}

.PanelContent {
  .padded & {
    padding: spacing(2) 0;
  }
}

.highlighted {
  border-color: $color-blue;
}
