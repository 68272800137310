
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
// .SiteContainer {}

.SiteContainerContent {
  flex: 1 0 auto;
  background: $color-coolgrey-l98;
  position: relative;
  max-width: spacing(100);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  @include mq($breakpoint-tablet) {
    border: 1px solid $color-coolgrey-l90;
    box-shadow: 0 4px 10px 0 rgba($color-coolgrey-l60, 0.5);
    border-radius: $radius-small;
  }
}
