
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
// .StepProgressWrapper {}

.StepProgressWrapperList {
  display: flex;
  width: 100%;
  list-style: none;
}
