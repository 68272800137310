
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.HeaderHero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: spacing(16);
  color: $color-white;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(71, 77, 96, 0.1);

  @include mq($breakpoint-tablet) {
    height: spacing(12);
    box-shadow: none;
  }
}

.HeaderHeroInner {
  padding-top: spacing(1.5);

  @include mq($breakpoint-tablet) {
    padding-top: spacing(0.5);
  }
}

.HeaderHeroClose {
  position: absolute;
  top: 0;
  right: 0;

  @include mq($breakpoint-tablet) {
    top: spacing(2);
    right: spacing(2);
  }

  a {
    color: $color-white-op70;
    transition: color $duration-standard $easing-standard;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba($color-white, 0.3);
      outline: 0;
      transition-duration: $duration-shortest;
      color: $color-white;
    }
  }
}

.HeaderHeroBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    125deg,
    $color-lightblue-l50 0%,
    $color-blue 94%
  );

  @include mq($breakpoint-tablet) {
    height: 50vh;
    min-height: spacing(30);
    max-height: spacing(55);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 100% 0;
    background-size: auto 100%;
    background-repeat: no-repeat;

    .bg-dashboard & {
      background-image: url('/image/bg-dashboard.png');
    }
  }
}
