
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
$gap: 40px;

.StepProgressItem {
  flex: 1 0 0;
  transition: color $duration-standard $easing-standard;
  text-align: center;
  color: $color-coolgrey-l70;
  min-width: 0;
  position: relative;
  user-select: none;
  padding: spacing(0.5);
  @include type-scale(2);
}

.StepProgressItemInner {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  @include font-smoothing;

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.StepProgressItemSeparator {
  position: absolute;
  top: spacing(2) - 1;
  left: 50%;
  height: 2px;
  width: calc(100% - #{$gap});
  transform: translateX($gap * 0.5);
  background-color: $color-coolgrey-l90;

  span {
    display: block;
    height: 100%;
    background-color: $color-blue;
    width: 0;
    transform-origin: 0 50%;
    transition: transform $duration-standard $easing-standard;

    .complete & {
      width: 100%;
    }
  }

  .StepProgressItem:last-child & {
    display: none;
  }
}

.complete,
.current {
  color: $color-blue;
}

// .incomplete {}
