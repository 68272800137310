
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.Footer {
  position: relative;
  padding-top: spacing(2);
  padding-bottom: spacing(4);
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq($breakpoint-tablet) {
    padding-top: spacing(4);
    padding-bottom: spacing(6);
  }

  a {
    color: $color-blue;
  }
}
